.wrapper {
    width: 100%;
    height: 100vh;
    font-family: 'Space Mono', monospace;
    display: flex;
    flex-direction: column;
    align-items: stretch;

}

.top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 2cm;
    margin-bottom: 1cm;
    font-weight: 600;
    font-size: 40pt;
}

.content {
    flex-grow: 1;
    margin: 0.5cm;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.content-left {
    flex-grow: 2;
    display: flex;
    justify-items: stretch;
    justify-content: center;
    align-items: stretch;
}

.content-right {
    flex-grow: 0.5;
    margin: 2cm;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.q-row {
    display: flex;
    flex-direction: row;
    padding: 0.5cm;
    font-size: 30pt;
}

.q-l {
    width: 4em;
    font-weight: 600;
}

.q-r {
    width: 4em;

}

.svg-container {
    margin: 1cm;
    border: 1px solid rgb(19, 56, 33);
    width: 100%;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    aspect-ratio: 1/1;
    flex: 1 0 auto;
}

.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}



.options {
    width: 100%;
    font-family: 'Space Mono', monospace;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.o-row {
    display: flex;
    flex-direction: row;
    padding: 0.5cm;
    font-size: 15pt;
    justify-items: stretch;
}

.o-l {
    width: 10em;
    font-weight: 600;
}

.o-r {
    flex-grow: 1;
    display: flex;
    justify-items: center;
    align-items: center;
}

button {
    flex-grow: 1;

}

textarea {
    width: 100%;
    height: 20em;
}