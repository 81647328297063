.wrapper {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: stretch;
  font-family: Space Mono, monospace;
  display: flex;
}

.top {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header {
  margin-top: 2cm;
  margin-bottom: 1cm;
  font-size: 40pt;
  font-weight: 600;
}

.content {
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
  margin: .5cm;
  display: flex;
}

.content-left {
  flex-grow: 2;
  justify-content: center;
  place-items: stretch stretch;
  display: flex;
}

.content-right {
  flex-direction: column;
  flex-grow: 2;
  align-items: center;
  margin: 2cm;
  display: flex;
}

.q-row {
  flex-direction: row;
  padding: .5cm;
  font-size: 30pt;
  display: flex;
}

.q-l {
  width: 4em;
  font-weight: 600;
}

.q-r {
  width: 4em;
}

.svg-container {
  width: 100%;
  vertical-align: top;
  aspect-ratio: 1 / 1;
  border: 1px solid #133821;
  flex: 1 0 auto;
  margin: 1cm;
  position: relative;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.options {
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  font-family: Space Mono, monospace;
  display: flex;
}

.o-row {
  flex-direction: row;
  justify-items: stretch;
  padding: .5cm;
  font-size: 15pt;
  display: flex;
}

.o-l {
  width: 10em;
  font-weight: 600;
}

.o-r {
  flex-grow: 1;
  place-items: center;
  display: flex;
}

button {
  flex-grow: 1;
}

textarea {
  width: 100%;
  height: 20em;
}

/*# sourceMappingURL=index.25043b8f.css.map */
